<template>
  <div id="app">
    <nav>
      <select v-model="$i18n.locale">
        <option value="zh">中文</option>
        <option value="en">English</option>
      </select>
    </nav>
    <FirstScreen />
    <SecondScreen />
    <ThirdScreen />
    <FourthScreen />
    <FifthScreen />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import FirstScreen from './components/FirstScreen.vue'
import SecondScreen from './components/SecondScreen.vue'
import ThirdScreen from './components/ThirdScreen.vue'
import FourthScreen from './components/FourthScreen.vue'
import FifthScreen from './components/FifthScreen.vue'

export default defineComponent({
  name: 'App',
  components: {
    FirstScreen,
    SecondScreen,
    ThirdScreen,
    FourthScreen,
    FifthScreen
  }
})
</script>

<style>
#app {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

select {
  padding: 5px 10px;
  border: none;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
}

/* 在现有样式下添加以下内容 */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  min-height: 100%;
}

.screen {
  min-height: 100vh;
}
</style>
