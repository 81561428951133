<template>
  <div class="screen fifth-screen">
    <div class="mission">
      <h2>{{ $t('fifthScreen.mission.title') }}</h2>
      <p>{{ $t('fifthScreen.mission.description') }}</p>
    </div>
    <div class="team">
      <h3>{{ $t('fifthScreen.team.title') }}</h3>
      <div class="team-members">
        <div v-for="(member, index) in teamMembers" :key="index" class="team-member">
          <img :src="require(`@/assets/team-member-${index + 1}.jpg`)" :alt="$t(`fifthScreen.team.members[${index}].name`)">
          <h4>{{ $t(`fifthScreen.team.members[${index}].name`) }}</h4>
          <p>{{ $t(`fifthScreen.team.members[${index}].role`) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FifthScreen',
  data() {
    return {
      teamMembers: [1, 2, 3]
    }
  }
}
</script>

<style scoped>
.fifth-screen {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 2rem;
  box-sizing: border-box;
}

.mission, .team {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.mission {
  margin-bottom: 4rem;
}

h2, h3 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.mission p {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

.team-members {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 3rem;
}

.team-member {
  flex-basis: calc(33.333% - 2rem);
  margin-bottom: 2rem;
  text-align: center;
}

.team-member img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}

h4 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.team-member p {
  font-size: 1rem;
}

@media (max-width: 1024px) {
  .team-member {
    flex-basis: calc(50% - 1rem);
  }
}

@media (max-width: 768px) {
  .fifth-screen {
    padding: 3rem 1rem;
  }

  h2, h3 {
    font-size: 2rem;
  }

  .mission p {
    font-size: 1rem;
  }

  .team-members {
    flex-direction: column;
    align-items: center;
  }

  .team-member {
    flex-basis: 100%;
    max-width: 300px;
  }
}
</style>
