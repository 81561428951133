<template>
  <div class="screen fourth-screen">
    <div class="grid">
      <div v-for="(scenario, index) in scenarios" :key="index" class="scenario">
        <h2>{{ $t(`fourthScreen.scenarios[${index}].title`) }}</h2>
        <p>{{ $t(`fourthScreen.scenarios[${index}].description`) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FourthScreen',
  data() {
    return {
      scenarios: ['commute', 'work', 'exercise', 'relax']
    }
  }
}
</script>

<style scoped>
.fourth-screen {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem; /* 统一四周的内边距 */
  box-sizing: border-box;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 2rem; /* 增加格子之间的间隔 */
  width: 100%;
  height: 100%;
  max-width: 1600px; /* 增加最大宽度以减少左右留白 */
  margin: 0 auto;
}

.scenario {
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: white;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 0; /* 移除圆角，改为直角矩形 */
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.scenario:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.scenario:nth-child(1) { background-image: url('@/assets/scenario-1.jpg'); }
.scenario:nth-child(2) { background-image: url('@/assets/scenario-2.jpg'); }
.scenario:nth-child(3) { background-image: url('@/assets/scenario-3.jpg'); }
.scenario:nth-child(4) { background-image: url('@/assets/scenario-4.jpg'); }

h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

p {
  font-size: 1rem;
  text-align: center;
}

@media (max-width: 768px) {
  .fourth-screen {
    padding: 1rem; /* 在小屏幕上减少内边距 */
  }

  .grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    gap: 1rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.9rem;
  }
}
</style>
