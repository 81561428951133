<template>
  <div class="screen third-screen">
    <h2>{{ $t('thirdScreen.title') }}</h2>
    <p>{{ $t('thirdScreen.description') }}</p>
  </div>
</template>

<script>
export default {
  name: 'ThirdScreen'
}
</script>

<style scoped>
.third-screen {
  background-image: url('@/assets/third-screen-bg.webp');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  padding: 0 2rem;
}

h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

p {
  font-size: 1.5rem;
  max-width: 800px;
  text-align: center;
}

@media (max-width: 768px) {
  .third-screen {
    background-image: url('@/assets/third-screen-bg-small.webp');
  }

  h2 {
    font-size: 2rem;
  }

  p {
    font-size: 1.2rem;
  }
}
</style>
